import Service, { inject as service } from '@ember/service';

import IntlService from 'ember-intl/services/intl';

import ENV from 'mobile-web/config/environment';
import addScriptModule from 'mobile-web/lib/utilities/add-script';

import AnalyticsService, { AnalyticsEvents, AnalyticsProperties } from './analytics';
import ErrorService from './error';
import FeaturesService from './features';

export type ArkoseEnforcementConfig = {
  publicKey: string;
  onCompleted(response: { token: string }): void;
  onReady(): void;
  onFailed(response: { token: string }): void;
  onHide(): void;
  onShown(): void;
  onError(response: { error: { error: string } }): void;
};

export type ArkoseEnforcement = {
  reset(): void;
  run(): void;
  setConfig(config: ArkoseEnforcementConfig): void;
};

export enum ArkoseChallengeAction {
  INTERNAL_LOGIN = 'INTERNAL_LOGIN',
  SUBMIT_ORDER = 'SUBMIT_ORDER',
}

export default class ArkoseChallengeService extends Service {
  // Service injections
  @service intl!: IntlService;
  @service error!: ErrorService;
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;

  private isScriptInitialized = false;
  private initializingScript = false;
  private scriptInitializationPromise?: Promise<void>;
  private arkoseEnforcement?: ArkoseEnforcement;
  private readonly actionKeyMap = {
    [ArkoseChallengeAction.INTERNAL_LOGIN]: ENV.ARKOSE_INTERNAL_LOGIN_PUBLIC_KEY,
    [ArkoseChallengeAction.SUBMIT_ORDER]: ENV.ARKOSE_SUBMIT_ORDER_PUBLIC_KEY,
  };

  async runChallengeAsync(action: keyof typeof ArkoseChallengeAction): Promise<string> {
    if (!this.isEnabled(action)) {
      return Promise.resolve('');
    }

    await this.initialize();

    return new Promise((resolve, reject) => {
      this.arkoseEnforcement!.reset();
      this.arkoseEnforcement!.setConfig({
        publicKey: this.actionKeyMap[action],
        onCompleted: (response: any) => {
          resolve(response.token);
        },
        onReady: () => {
          this.arkoseEnforcement!.run();
        },
        onFailed: response => {
          this.error.sendExternalError(Error('User failed solving arkose labs challenge'), {
            cause: 'arkose-labs-challenge',
            arkoseToken: response.token,
            action,
          });
          reject({
            message: this.intl.t('mwc.challenge.arkoseError'),
          });
        },
        onHide: () => {
          reject({
            message: this.intl.t('mwc.challenge.arkoseError'),
          });
        },
        onShown: () => {
          this.analytics.trackEvent(AnalyticsEvents.ChallengeShown, () => ({
            [AnalyticsProperties.ChallengeProvider]: 'Arkose',
            [AnalyticsProperties.ChallengeAction]: action,
          }));
        },
        onError: (response: any) => {
          this.error.sendExternalError(Error('Error when solving arkose labs challenge.'), {
            cause: 'arkose-labs-challenge',
            arkoseErrorCode: response.error.error,
            arkoseErrorDetails: response.error,
            action,
          });
          reject({
            message: this.intl.t('mwc.challenge.arkoseError'),
          });
        },
      });
    });
  }

  private isEnabled(action: keyof typeof ArkoseChallengeAction): boolean {
    //when key is not populated we'll treat it as the arkose is disabled for the environment
    return !!this.actionKeyMap[action] && !this.actionKeyMap[action].startsWith('__ARKOSE_');
  }

  public initialize(): Promise<void> {
    if (this.isScriptInitialized) return Promise.resolve();
    if (this.initializingScript) return this.scriptInitializationPromise!;

    this.initializingScript = true;

    this.scriptInitializationPromise = new Promise(resolve => {
      window.arkose_setupEnforcement = (arkoseEnforcement: ArkoseEnforcement): void => {
        this.arkoseEnforcement = arkoseEnforcement;
        this.isScriptInitialized = true;
        this.initializingScript = false;
        delete window.arkose_setupEnforcement;
        resolve();
      };

      if (this.features.flags['debug-arkose-loading-script-error-olo-96392']) {
        // subscribe to CSP event and log any arkose related events
        document.addEventListener('securitypolicyviolation', (e: SecurityPolicyViolationEvent) => {
          if (e.blockedURI.indexOf('arkoselabs') > -1) {
            this.error.sendExternalError(
              Error('Debug: CSP error loading arkose enforcment script.'),
              {
                originalPolicy: e.originalPolicy,
                blockedURI: e.blockedURI,
                statusCode: e.statusCode.toString(),
                referrer: e.referrer,
              }
            );
          }
        });
      }

      addScriptModule.addScript(
        `https://olo-api.arkoselabs.com/v2/api.js`,
        undefined,
        () => {
          this.error.sendExternalError(Error('Failed to load arkose enforcment script.'), {
            cause: 'arkose-labs-challenge',
          });

          // the onerror script doesn't provide much info about an error
          // therefore in that case we'll make another xhr request for arkose script to obtain http error details
          if (this.features.flags['debug-arkose-loading-script-error-olo-96392']) {
            fetch(`https://olo-api.arkoselabs.com/v2/api.js`)
              .then(response => {
                if (!response.ok) {
                  response.text().then(responseText => {
                    this.error.sendExternalError(
                      Error('Debug: http error loading arkose enforcment script.'),
                      {
                        responseStatus: response.status.toString(),
                        responseStatusText: response.statusText,
                        responseText,
                      }
                    );
                  });
                }
              })
              .catch(error => {
                this.error.sendExternalError(
                  Error('Debug: application error loading arkose enforcment script.'),
                  {
                    errorMessage: JSON.stringify(error),
                  }
                );
              });
          }
        },
        { 'data-callback': 'arkose_setupEnforcement' }
      );
    });

    return this.scriptInitializationPromise;
  }
}

declare module '@ember/service' {
  interface Registry {
    arkoseChallenge: ArkoseChallengeService;
  }
}
