import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import * as PopoverItem from 'mobile-web/components/popover-item';
import { LoginProvider } from 'mobile-web/models/bootstrap-data';
import ChannelService from 'mobile-web/services/channel';
import SessionService from 'mobile-web/services/session';

import { HeaderButtonMode } from '../header-button';

import style from './index.m.scss';

interface Args {
  // Required arguments
  loggedIn: boolean;
  onLogout: Action;
  showOffersInbox: boolean;

  // Optional arguments
  isEmbedded?: boolean;
}

interface Signature {
  Args: Args;
}

export default class AccountButton extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service session!: SessionService;
  @service channel!: ChannelService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get accountActions(): PopoverItem.Model[] {
    const base: PopoverItem.Model[] = [
      {
        label: this.intl.t('mwc.header.accountButtonRecentOrdersLabel'),
        route: 'recent-orders',
        icon: 'history',
        class: this.args.isEmbedded ? this.style.embeddedRecentOrders : undefined,
      },
      {
        label: this.intl.t('mwc.saveFavorite.accountButtonFavoriteOrders'),
        route: 'favorites',
        icon: 'favorite',
        class: this.args.isEmbedded ? this.style.embeddedFavoriteOrders : this.style.favoriteOrders,
      },
      {
        label: this.intl.t('mwc.header.accountButtonSettingsLabel'),
        route: 'my-account',
        icon: 'account',
        testSelector: 'my-account',
        class: this.args.isEmbedded ? this.style.embeddedAccountSettings : undefined,
      },
      {
        label: this.intl.t('mwc.header.accountButtonSignOutLabel'),
        action: this.args.onLogout,
        icon: 'sign-out-legacy',
        testSelector: 'sign-out',
        class: this.args.isEmbedded ? this.style.embeddedSignOut : undefined,
      },
    ];

    const offersPosition = base.length - 1;

    return this.args.showOffersInbox
      ? [
          ...base.slice(0, offersPosition),
          {
            label: this.intl.t('mwc.header.accountButtonOffersLabel'),
            route: 'rewards-search',
            icon: 'loyalty',
            testSelector: 'rewards-search',
            class: this.args.isEmbedded ? this.style.embeddedOffersInbox : undefined,
          },
          base[offersPosition],
        ]
      : base;
  }

  get mode(): HeaderButtonMode {
    return this.session.internalSignOnAllowed ? 'link' : 'button';
  }

  get loginProviders(): LoginProvider[] {
    return this.channel.current?.loginProviders ?? [];
  }

  get supportsOloLogin(): boolean {
    return this.channel.settings?.supportsOloLogin ?? false;
  }

  get labelText() {
    if (
      !this.supportsOloLogin &&
      this.loginProviders.length === 1 &&
      this.loginProviders[0].category !== 'Legacy'
    ) {
      return (
        this.loginProviders[0].displayText || this.intl.t('mwc.header.accountButtonSignInLabel')
      );
    }

    return this.intl.t('mwc.header.accountButtonSignInLabel');
  }
  // Constructor

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onLogin(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.session.goToLogin();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountButton: typeof AccountButton;
  }
}
