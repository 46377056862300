import { action } from '@ember/object';
import { service } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import style from './index.m.scss';

interface Args {
  // Required arguments
  name: string;
  svg: string;

  // Optional arguments
  route?: string;
  onClick?: Action;
  disabled?: boolean;
  testSelector?: string;
  label?: string;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class SideMenuLink extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get labelText(): string {
    return this.args.label || this.intl.t('mwc.sideMenu.' + this.args.name + 'Link');
  }

  get testSelector(): string {
    if (this.args.route) {
      return 'side-menu-' + dasherize(this.args.name);
    }
    return dasherize(this.args.name);
  }
  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onLinkClick(e: Event) {
    this.args.onClick?.(e);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SideMenuLink: typeof SideMenuLink;
  }
}
