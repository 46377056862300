import Service, { inject as service } from '@ember/service';

import { DisplayItem, SupportedCountries, SupportedCurrencies } from '@olo/pay-digital-wallets-js';

import VendorService from 'mobile-web/services/vendor';

import BasketService from './basket';

export default class PaymentOptionsService extends Service {
  @service vendor!: VendorService;
  @service basket!: BasketService;

  get displayItems(): DisplayItem[] {
    const items: DisplayItem[] = [];

    if (this.basket.basket) {
      items.push({
        label: 'Subtotal',
        type: 'SUBTOTAL',
        price: this.basket.basket.subTotal.toFixed(2),
      });

      items.push({
        label: 'Tax',
        type: 'TAX',
        price: this.basket.basket?.salesTax ? this.basket.basket.salesTax.toFixed(2) : '0',
      });

      items.push({
        label: 'Tip',
        type: 'LINE_ITEM',
        price: this.basket.basket?.tip ? this.basket.basket.tip.toFixed(2) : '0',
      });

      // If there is a delivery address, add the delivery charge to the display items
      if (this.basket.basket?.deliveryAddress) {
        items.push({
          label: 'Delivery charge',
          type: 'LINE_ITEM',
          price: this.basket.basket?.deliveryCharge
            ? this.basket.basket?.deliveryCharge.toFixed(2)
            : '0',
        });
      }

      if (this.basket.basket?.discounts) {
        for (const discount of this.basket.basket.discounts) {
          items.push({
            label: 'Discount: ' + discount.description,
            type: 'DISCOUNT',
            price: (Math.abs(discount.amount) * -1).toFixed(2),
          });
        }
      }

      if (this.basket.basket?.hasCoupon) {
        items.push({
          label: 'Coupon: ' + this.basket.basket.coupon?.description,
          type: 'DISCOUNT',
          price: this.basket.basket.couponDiscount.toFixed(2),
        });
      }
    }

    return items;
  }

  get country(): SupportedCountries {
    return (this.vendor.vendor?.address.country as SupportedCountries) ?? 'US';
  }

  get currency(): SupportedCurrencies {
    return this.vendor.currency as SupportedCurrencies;
  }

  get totalPrice(): string {
    return this.calculateTotal(this.displayItems);
  }

  private calculateTotal(items: DisplayItem[]): string {
    return items
      .reduce((sum, item) => {
        if (item.type === 'DISCOUNT') {
          return sum - parseFloat(item.price);
        }
        return sum + parseFloat(item.price);
      }, 0)
      .toFixed(2);
  }
}

declare module '@ember/service' {
  interface Registry {
    paymentOptions: PaymentOptionsService;
  }
}
