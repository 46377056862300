import { service } from '@ember/service';
import DS from 'ember-data';
import { ModelRegistry } from 'ember-data/model';
import Store from 'ember-data/store';
import RSVP from 'rsvp';

import FeaturesService from 'mobile-web/services/features';

import ApplicationAdapter from './application';

export default class OrderAubmissionAdapter extends ApplicationAdapter {
  @service features!: FeaturesService;

  pathForType(): string {
    return 'orders';
  }

  createRecord<K extends keyof ModelRegistry>(
    store: Store,
    type: ModelRegistry[K],
    snapshot: DS.Snapshot<K>
  ): RSVP.Promise<any> {
    if (!this.features.flags['arkose-challenge-submit-order-olo-95337']) {
      return super.createRecord(store, type, snapshot);
    }

    const data = {};

    const serializer = store.serializerFor('order-submission');

    serializer.serializeIntoHash(data, type, snapshot);

    const url = this.buildURL<K>(snapshot.modelName, snapshot.id, snapshot, 'createRecord');
    return this.ajax(url, 'POST', {
      data,
      headers: { 'arkose-token': snapshot.adapterOptions.arkoseToken },
    });
  }
}
