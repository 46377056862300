export default {
  "root": "_root_nh2q8e",
  "filterContainer": "_filterContainer_nh2q8e",
  "filter": "_filter_nh2q8e",
  "menusearch": "_menusearch_nh2q8e",
  "active": "_active_nh2q8e",
  "typeahead": "_typeahead_nh2q8e",
  "searchResult": "_searchResult_nh2q8e",
  "brandCarouselSticky": "_brandCarouselSticky_nh2q8e",
  "title": "_title_nh2q8e",
  "categoryListContainer": "_categoryListContainer_nh2q8e",
  "categoryContainer": "_categoryContainer_nh2q8e",
  "category": "_category_nh2q8e"
};
